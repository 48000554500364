<template>
  <div>
    <vs-row>
      <ValidationObserver
        v-slot="{handleSubmit}"
        ref="validationObserver"
        class="w-full"
      >
        <form @keydown.enter.prevent="handleSubmit(onSubmit)">
          <vs-col
            class="mb-2 p-1"
            vs-w="6"
          >
            <tm-select
              name="قيمة الخاصية"
              :options="variantValues"
              validation-rules="required"
              label="value"
              v-model="variant_value_id"
              :reduce="value => value.id"
            />
          </vs-col>
          <vs-col
            class="mb-2 p-1"
            vs-w="6"
          >
            <tm-select
              name="اسم الخاصية"
              :options="variants"
              validation-rules="required"
              label="name"
              v-model="variantKey"
              :reduce="option => option"
              @input="selectVariantKey"
            />
          </vs-col>
          <vs-col
            vs-type="flex"
            vs-justify="center"
            vs-align="center"
            vs-w="12"
          >
            <vs-button
              ref="loadableButton"
              id="button-with-loading"
              class="vs-con-loading__container"
              color="primary"
              type="filled"
              size="small"
              icon-pack="feather"
              icon="icon-plus"
              @click.prevent="handleSubmit(onSubmit)"
              :disabled="disabled"
            >
              اضافة
            </vs-button>
          </vs-col>
        </form>
      </ValidationObserver>
    </vs-row>
    <vs-divider>الخصائص</vs-divider>
    <div class="mb-6">
      <div
        v-for="(values, key) in supProductVariants"
        :key="values.id"
      >
        <vs-alert
          :title="key"
          active="true"
          class="mb-2"
        >
          <vs-chip
            v-for="(value, index) in values"
            :key="index"
            transparent
            color="primary"
            closable
            @click="deleteSubProductVariant(value)"
          >
            {{ value.value }}
          </vs-chip>
        </vs-alert>
      </div>
    </div>
  </div>
</template>

<script>
import {RepositoryFactory} from "../../repositories/ecommerceRepositoryFactory";
import TmSelect from "../../../shared/shared-components/TmSelect";

const variantsRepo = RepositoryFactory.get('variantKey');
const subProductVariantRepo = RepositoryFactory.get('subProductVariant');
export default {
  name: "SubProductVariants",
  data() {
    return {
      variants: [],
      variant_value_id: null,
      disabled: false,
      variantKey: null,
      variantValues: [],
    };
  },
  props: {
    subProduct: {
      type: Object,
      required: true,
    },
  },
  computed: {
    supProductVariants() {
      return this.lodash.groupBy(this.subProduct.sub_product_variants, 'key');
    }
  },
  methods: {
    onSubmit() {
      this.load();
      let variant = {
        sub_product_id: this.subProduct.id,
        variant_value_id: this.variant_value_id
      };
      subProductVariantRepo.store(variant).then((response) => {
        response.data.variant_value.sub_product_variant_id = response.data.id;
        this.subProduct.sub_product_variants.push(response.data.variant_value);
        this.closeLoading();
      });
    },
    deleteSubProductVariant(value) {
      let id = value.sub_product_variant_id;
      subProductVariantRepo.delete(id).then(() => {
        let index = this.subProduct.sub_product_variants.findIndex(subProductVariant => {
          return subProductVariant.sub_product_variant_id === id;
        });
        this.subProduct.sub_product_variants.splice(index, 1);
      });
    },
    load() {
      this.disabled = true;
      this.$vs.loading({
        background: this.backgroundLoading,
        color: this.colorLoading,
        container: "#button-with-loading",
        scale: 0.45
      });
    },
    closeLoading() {
      this.disabled = false;
      this.$vs.loading.close("#button-with-loading > .con-vs-loading");
    },
    selectVariantKey(selectedValue) {
      this.variantKey = selectedValue.name;
      this.variantValues = selectedValue.variantValues;
    },
    addToSubProductVariants(savedVariant) {
      this.subProduct.sub_product_variants.push({
        id: savedVariant.variant_value.id,
        key: "الحجم",
        sub_product_variant_id: 7,
        value: "large"
      });
    }
  },
  components: {
    TmSelect
  },
  created() {
    variantsRepo.getAllVariant().then((response) => {
      this.variants = response;
    });
  }
};
</script>
